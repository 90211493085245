<template>
    <div class="">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">BOOKING <span class="pl-40">{{currentBusketItem.day}}</span></h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex gaps mt-10">
            <div class="box grow flex column card-base clickable"
            style="flex:1 0 100px;"
             @click="switchType(index)"
             v-for="(type, index) in types" :key="index" :class="{ 'bg-accent': index === typeId }">
                <h4 class="box pl-10 m-5">{{type.name}}</h4>
                <h5 class="box right bottom pb-5 pr-5 m-5" :class="{ 'o-050': index !== typeId }">{{typesPrice[index]}} AED</h5>
            </div>
        </div>
        <div class="flex gaps mt-10">
            <div class="box grow flex column card-base clickable"
            style="flex:1 0 100px;"
             @click="switchType(index)"
             v-for="(type, index) in types" :key="index" :class="{ 'bg-accent': index === typeId }">
                <h4 class="box pl-10 m-5">{{type.name}}</h4>
                <h5 class="box right bottom pb-5 pr-5 m-5" :class="{ 'o-050': index !== typeId }">{{typesPrice[index]}} AED</h5>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="flex wrap grid-12">
            <div class="box card-base m-5 clickable session-time"
              @click="switchSession(index)"
              v-for="(ses, index) in sessions.sessions" :key="index + 'sessions'"
              :class="{ 'bg-accent': index == sessionId || index == sessionId2}">
                <div class="p-10 flex column">
                    <h4 class="box pb-10 center">{{ses.start}} - {{ses.end}}</h4>
                    <div class="box flex justify-space-between">
                      <div class="box m-0" v-if="index == sessionId || index == sessionId2">{{ses.capacity}}</div>
                      <div class="box m-0 success-text" v-else-if="ses.capacity > 30">{{ses.capacity}}</div>
                      <div class="box m-0 warning-text" v-else-if="ses.capacity > 10">{{ses.capacity}}</div>
                      <div class="box m-0 danger-text" v-else>{{ses.capacity}}</div>
                      <div class="box self-flex-end m-0 font-size-13 right" :class="{ 'o-050': index !== sessionId && index !== sessionId2}">{{ses.price | priceByIndex(typeId)}} AED</div>
                    </div>
                </div>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>
        <div class="flex column gaps">
          <div class="box flex gaps">
            <div class="box grow flex gaps">
              <div class="box left center" style="min-width: 85px;">
                  <h4 class="mt-10 mb-5 o-050">PERSON</h4>
              </div>
              <div class="box">
                <el-select :popper-append-to-body="false" style="min-width: 180px;"
                 v-model="currentBusketItem.person" placeholder="Select Person" @change="handleChangePerson">
                  <el-option
                    v-for="item in basketWaiwers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- <div class="flex gaps mt-10" v-if="currentBusketItem.person !== ''">
            <div class="box grow flex">
              <div class="box grow flex column card-base clickable"
                :class="{'bg-accent' : currentBusketItem.hasOwnProperty('discount') && currentBusketItem.discount.type === 'Group'}"
                @click="handleGoupDiscount({ name: currentBusketItem.personData.group.name, per: currentBusketItem.personData.group.discount })"
                v-if="currentBusketItem.hasOwnProperty('personData') && currentBusketItem.personData.hasOwnProperty('group') && currentBusketItem.personData.group.hasOwnProperty('name')">
                  <h3 class="box pl-10 m-5 ellipsis">{{currentBusketItem.personData.group.name}}</h3>
                  <h4 class="box right bottom pb-5 pr-5 m-5" :class="{ 'o-050': !currentBusketItem.hasOwnProperty('discount') || currentBusketItem.discount.type !== 'Group' }">Discount {{currentBusketItem.personData.group.discount}}%</h4>
              </div>
            </div>
          </div>
          <div class="flex gaps" v-if="currentBusketItem.person === ''">
            <div class="box flex gaps">
              <div class="box left center" style="min-width: 85px;">
                  <h4 class="mt-10 mb-5 o-050">PERSONS</h4>
              </div>
              <div class="box">
                  <el-input-number v-model="currentBusketItem.times" :step="1" :min="0" @change.native.prevent="switchPersons"></el-input-number>
              </div>
            </div>
          </div> -->
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SaleBooking',

  props: {
    basket: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  data () {
    return {
      times: 0,
      selectedPerson: ''
    }
  },
  computed: {
    ...mapState({
      types: state => state.type.types
      // basket: state => state.basket.basket
    }),
    ...mapGetters([
      'daySessions',
      'defaultTypeId',
      'getGroupById',
      'basketWaiwers'
    ]),
    currentBusketItem () {
      let row = this.basket.find(b => b.basketId === this.$route.params.id)
      return row !== undefined ? row : {
        typeId: 0,
        sessionId: 0,
        desc: [],
        times: 0,
        rate: 0,
        day: 0
      }
    },
    sessions () {
      return this.daySessions(this.currentBusketItem.day)
    },
    sessionId () {
      if (this.basket.length > this.$route.params.id && this.currentBusketItem.hasOwnProperty('sessionId')) {
        return this.currentBusketItem.sessionId
      } else {
        return 0
      }
    },
    sessionId2 () {
      if (this.basket.length > this.$route.params.id && this.currentBusketItem.hasOwnProperty('sessionId2')) {
        return this.currentBusketItem.sessionId2
      } else {
        return -1
      }
    },
    typeId () {
      if (this.basket.length > this.$route.params.id && this.currentBusketItem.hasOwnProperty('typeId')) {
        return this.currentBusketItem.typeId
      } else {
        return 0
      }
    },
    typesPrice () {
      let prices = this.sessions.sessions[this.currentBusketItem.sessionId].price
      return prices.split(',').map(e => Number(e.trim()))
    }
  },
  methods: {
    switchType () {},
    switchSession () {},
    handleChangePerson () {}
  },
  filters: {
    priceByIndex: function (value, typeId) {
      let prices = value.split(',')
      let price = prices.length >= typeId ? prices[typeId] : 0
      return price || ''
    }
  }
}
</script>
